import { Button, Icon, Paragraph, ProgressIndicator } from '@boxine/tonies-ui'
import * as icons from '@boxine/tonies-ui/icons'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { LayoutV2 } from '../../layouts/LayoutV2'

type LoadingLayoutProps = {
  link?: {
    href: string
    text: string
  }
  message?: string
}

const Wrapper = styled.div`
  align-content: center;
  display: grid;
  gap: 2rem;
  height: 100vh;
  justify-items: center;
  width: 100vw;
`

const MessageBox = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;

  svg {
    margin-top: 0.2em;
  }

  p {
    margin-left: 0.5rem;
  }
`

export function LoadingLayout({ link, message }: LoadingLayoutProps) {
  const theme = useContext(ThemeContext)

  return (
    <LayoutV2>
      <Wrapper>
        <Icon
          type={icons.toniesLogo}
          width="8rem"
          fillAll={theme.colors.primary}
        />
        {message && (
          <MessageBox>
            <Paragraph size={2} color="primary">
              {message}
            </Paragraph>
          </MessageBox>
        )}
        <ProgressIndicator
          data-testid="checkout-progress-indicator"
          styling="secondary"
          height="2rem"
          width="2rem"
        />
        {link && (
          <Button as="a" variant="secondary" href={link.href}>
            {link.text}
          </Button>
        )}
      </Wrapper>
    </LayoutV2>
  )
}
