import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useShop } from '../../hooks/useShop'
import { LayoutV2 } from '../../layouts/LayoutV2'
import { LoadingLayout } from '../../components/LoadingLayout'

export function AudioLibraryRedirect() {
  const { createLinkForTunesOverview } = useShop()
  const { t } = useTranslation(['audio-library-detail'])

  useEffect(() => {
    const shopLink = createLinkForTunesOverview()

    if (shopLink) {
      window.location.href = shopLink
    }
  }, [createLinkForTunesOverview])

  console.log('AudioLibraryRedirect.tsx: AudioLibraryRedirect()')

  return (
    <LayoutV2>
      <LoadingLayout
        message={t('RedirectMessage')}
        link={t('RedirectLinkText')}
      />
    </LayoutV2>
  )
}
